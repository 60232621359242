import { createStore } from "vuex";
import {toRaw} from "vue";

const store = createStore({
    state: {
        user: undefined,
        cart: []
    },
    mutations:{
        SET_USER(state, user){
            state.user = user;
            sessionStorage.setItem("user", JSON.stringify(state.user));
        },
        UNSET_USER(state){
            state.user = undefined;
            sessionStorage.setItem("user", JSON.stringify(state.user));
        },
        ADD_TO_CART(state, product){
            const index = state.cart.findIndex(p => {
                return p.id === product.id
            })

            const size = product.size;
            product.sizes = state.cart[index] === undefined ? [size] : [...state.cart[index].sizes, size];

            if(typeof product.amount === 'undefined'){
                product = {...product, amount: 1};
            }

            if (index !== -1){
                state.cart[index].amount += product.amount;
                state.cart[index].sizes = product.sizes;
            }
            else {
                state.cart = [...state.cart, product];
            }

            sessionStorage.setItem("cart", JSON.stringify(state.cart));
        },
        SET_CART(state, cart){
            state.cart = cart
            sessionStorage.setItem("cart", JSON.stringify(state.cart))
        },
        REMOVE_FROM_CART(state, product){
            const updateCart = [...JSON.parse(sessionStorage.getItem("cart")).filter(p => p.id !== product.id)];
            let cart = toRaw(updateCart)
            state.cart = cart
            console.log(cart)
            sessionStorage.setItem("cart", JSON.stringify(state.cart))
        },
        CHANGE_PRODUCT_AMOUNT(state, {productId, amount}){
            const index = state.cart.findIndex(p => {
                return p.id === productId
            })
            if (index !== -1)
                state.cart[index].amount = amount

            sessionStorage.setItem("cart", JSON.stringify(state.cart))
        }
    },
    actions:{
    },
    getters:{

    }
});
export default store;