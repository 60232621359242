<template>
  <div className="checkout-container">
    <section class="shopping-cart dark">
      <div class="container">
        <section class="page-header">
          <div class="overly"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="content text-center">
                  <h3 class="mb-3">Carrinho</h3>
                  <p>Todos os produtos do adicionados no seu carrinho.</p>

                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent justify-content-center">
                      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Carrinho</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="content">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <CartProductCard v-for="product in cart" :product="product" :key="product.id" />
            </div>
            <div class="col-md-12 col-lg-4">
              <div class="summary">
                <h3>Finalizar</h3>
                <div class="summary-item"><span class="text">Subtotal</span><span class="price">R$ {{ Number((getResume()).toFixed(2)) }}</span></div>
                <div class="summary-item"><span class="text">Desconto</span><span class="price">R$ -0</span></div>
                <div class="summary-item"><span class="text">Tudo</span><span class="price">R$ {{ Number((getResume()).toFixed(2)) }}</span></div>
                <a-button @click="checkout"  style="width: 100%" class="btn-cart" type="primary">Comprar</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CartProductCard from '@/components/CartProducts/CartProducts.vue';
import { mapState } from 'vuex';
import './CartView.css';
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";

export default{
  name: "CartView",
  components: { CartProductCard },
  computed: mapState({
    user: (state) => state.user,
    cart: (state) => state.cart,
  }),
  methods:{
    async saveUser(){
      const body = {
        phone: 'Não Cadastrado',
        name: this.user.name,
        cpf: 'Não Cadastrado',
        email: this.user.email,
        userId: sessionStorage.getItem('userId'), id: sessionStorage.getItem('userId')
      };

      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Customers', {
        method: 'POST',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
        body: JSON.stringify(body)
      }).then(response => response.text())
          .then(() => {
            this.loading = false;
            sessionStorage.setItem('userDetails', JSON.stringify(body));
          }).catch(error => console.log("Error detected: " + error));
    },
    async checkUser() {
      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Customers/' + sessionStorage.getItem('userId'), {
        method: 'GET',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
      }).then((result) => {
        if(result.status === 500 || result.status === 400){
          this.saveUser();
        }
      }).catch(() => {
          this.saveUser();
      });
    },
    login(){
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      signInWithPopup(auth, provider)
          .then((result) => {
            const credential = GoogleAuthProvider.credential(result._tokenResponse.idToken, result.user.accessToken);
            const user = result.user;
            this.$store.commit('SET_USER', {
              name: user.displayName,
              email: user.email,
              refreshToken: user.refreshToken,
              accessToken: user.accessToken,
              credential: credential,
            });
            if(this.getResume() > 0){
              this.$router.push('/checkout');
            }
            sessionStorage.setItem('userId', user.uid);
            sessionStorage.setItem('address', undefined);
            this.checkUser();
          });
    },
    getResume(){
      let resume = 0;
      this.cart.map(product => {
        resume += product.price * (typeof product.amount === 'undefined' ? 1 : product.amount);
      });
      return resume;
    },
    checkout(){
      if(this.user === undefined){
        this.login();
      }
      if(this.getResume() > 0 && this.user !== undefined){
        this.$router.push('/checkout');
      }
    },
  },
}
</script>