import { createRouter, createWebHistory } from 'vue-router'

import CategoryView from '../views/Category/CategoryView.vue'
import CartView from '../views/Cart/CartView.vue'
import ProductDetailsView from '../views/ProductDetails/ProductDetailsView.vue'
import HomeView from '@/views/Home/HomeView';
import CheckoutView from '@/views/Checkout/CheckoutView';
import AccountView from '@/views/Account/AccountView';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/category/:id',
      name: 'category',
      component: CategoryView
    },
    {
      path: '/cart',
      name: 'cart',
      component: CartView
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: CheckoutView
    },
    {
      path: '/account',
      name: 'account',
      component: AccountView
    },
    {
      path: '/product/:id',
      name: 'product',
      component: ProductDetailsView
    },
  ]
})

export default router