import App from './App.vue'
import { createApp } from "vue";
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import { initializeApp } from 'firebase/app';


const firebaseConfig = {
    apiKey: 'AIzaSyDCsdBq2d5gyZAgNoWWQk86nMakAgfL6gA',
    authDomain: 'ecommerce-paware.firebaseapp.com',
    projectId: 'ecommerce-paware',
    storageBucket: 'ecommerce-paware.appspot.com',
    messagingSenderId: '476985252887',
    appId: "1:476985252887:web:c5a8905213acf7b6f71052'"
};

initializeApp(firebaseConfig);

const app = createApp(App);
app.use(router)
app.use(store)
app.use(Antd)
app.mount("#app")