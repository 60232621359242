
<template>
  <div class="product rounded">
    <div @click="this.$router.push('/product/' + product.id)"  class="box-up">
      <img class="img-fluid w-100 rounded img" :src="product.image" alt="">
      <div class="img-info">
        <div class="info-inner">
          <span class="p-name">{{ product.name }}</span>
          <span class="p-company">{{product.briefDescription}}</span>
        </div>
        <div class="a-size">Tamanhos:
          <span v-for="s in product.sizeList" :key="s" class="size">{{ s + ' ' }}</span></div>
      </div>
    </div>
    <div class="box-down rounded">
      <div class="h-bg">
        <div class="h-bg-inner"></div>
      </div>
      <a @click="showModal" class="cart">
        <span class="price">R$ {{product.price}}</span>
        <span class="add-to-cart">
          <span class="txt">Adicionar ao Carrinho</span>
        </span>
      </a>
    </div>
  </div>
  <a-modal :footer="null" v-model:visible="showProductModal" title="" @ok="closeModal">
    <div class="product-content">
      <h2 class = "product-title">{{ product.name }}</h2>
      <a href = "#" class = "product-link">frey store produtos</a>

      <div class = "product-price">
        <p class = "new-price">Por Apenas: <span>R$ {{ product.price }}</span></p>
      </div>

      <div class = "product-detail">
        <h2>Descrição: </h2>
        <p>{{ product.description }}</p>
        <ul>
          <li>Disponível: <span>Em Estoque</span></li>
          <li>Tamanhos:
            <span v-for="s in product.sizeList" :key="s">{{ s + ' ' }}</span>
          </li>
          <li>Entrega: <span>Frete Grátis entre 3 a 7 dias</span></li>
        </ul>
      </div>
      <a-divider/>
      <a-radio-group v-model:value="size" name="radioGroup">
        <a-radio v-for="s in product.sizeList" :key="s" :value="s">{{ s }}</a-radio>
      </a-radio-group>
      <a-divider/>
      <a-button style="width: 100%" class="btn-cart" type="primary" @click="checkAmountProduct({...product})">Adicionar</a-button>
    </div>
  </a-modal>
</template>

<script>

import './ProductCard.css'
import { notification } from "ant-design-vue";
import {mapState} from "vuex";
import '../../views/ProductDetails/ProductDetailsView.css';


export default{
  name: "ProductCard",
  data(){
    return{
      showProductModal: false,
      size: ''
    }
  },
  props: {
    product: Map,
  },
  computed: mapState({
    cart: (state) => state.cart,
  }),
  methods:{
    closeModal() {
      this.showProductModal = false;
    },
    showModal() {
      this.showProductModal = true;
    },
    addToCart(product){
      this.$store.commit('ADD_TO_CART', {...product, size: this.size});
      notification.destroy();
      const openNotification = () => {
        notification.open({
          message: 'Produto adicionado ao carrinho.',
          description: product.description,
        });
      };
      openNotification();
    },
    checkAmountProduct(product){
      if(this.size === '') {
        notification.destroy();
        const openNotification = () => {
          notification.open({
            message: this.product.name,
            description: 'Por favor escolha um tamanho.',
          });
        };
        return openNotification();
      }

      let cart = JSON.parse(JSON.stringify(this.cart))
      const isFound = cart.some(p => {
        return p.id === product.id;
      });
      if(isFound){
        if(cart.find(p => p.id === product.id).amount <= 14){
          this.addToCart(product);
        }
      }else{
        this.addToCart(product);
      }
    }
  },
}
</script>