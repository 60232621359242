<script setup>

</script>
<template>
  <div class="box-animation">
    <div class="animation01">
      <div class="line_content line_content01">
        <div class="line_wrapper line_wrapper01">
          <div class="line01"></div>
          <div class="line02"></div>
        </div>
      </div>
      <div class="line_content line_content02">
        <div class="line_wrapper line_wrapper02">
          <div class="line02"></div>
          <div class="line01"></div>
        </div>
      </div>
    </div>
    <div class="animation02">
      <div class="square">
        <span class="square_item square_item01"></span>
        <span class="square_item square_item02"></span>
        <span class="square_item square_item03"></span>
        <span class="square_item square_item04"></span>
        <span class="square_item square_item05"></span>
        <span class="square_item square_item06"></span>
        <span class="square_item square_item07"></span>
        <span class="square_item square_item08"></span>
        <span class="circle_item01"></span>
        <span class="circle_item02"></span>
        <span class="circle_item03"></span>
        <span class="circle_item04"></span>
        <span class="circle_item05"></span>
        <span class="circle_item06"></span>
        <span class="circle_item01_1"></span>
        <span class="circle_item02_2"></span>
        <span class="circle_item03_3"></span>
        <span class="circle_item04_4"></span>
        <span class="circle_item05_5"></span>
        <span class="circle_item06_6"></span>
      </div>
    </div>
    <div class="animation03">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="animation04">
      <div class="name_plate"></div>
      <div class="name">
        <div class="name-animation">
          <div class="box">

            <div class="title">
              <span class="block"></span>
              <h1>Frey Store<span></span></h1>
            </div>

            <div class="role">
              <div class="block"></div>
              <p>Produdos de qualidade em um só lugar.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import './TopHeader.css';
import './AnimationTItle.scss'
export default{
  name: "TopHeader",
}
</script>