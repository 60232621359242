<template>
  <div className="single-product-container">
    <section class="page-header">
      <div class="overly"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="content text-center">
              <h3 class="mb-3">Detalhes do Produto</h3>
              <p>{{ product.description }}</p>

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent justify-content-center">
                  <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Detalhes do Produto</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class = "card-wrapper">
      <div class = "card">
        <div class = "product-imgs">
          <div class="img-display">
            <div v-if="product.show" class = "img-showcase">
              <img v-for="img in product.imageList" :key="img" :src="img" :alt="product.name"/>
            </div>
            <div v-else class="img-showcase">
              <img key="noimage" src="@/assets/noimage.png" alt="noimagealt"/>
            </div>
          </div>
          <div class="img-select">
            <div v-for="img in product.imageList" :key="img" class = "img-item">
              <a href="#" :data-id="img">
                <img :src="img" :alt="product.name"/>
              </a>
            </div>
          </div>
        </div>

        <div class = "product-content">
          <h2 class = "product-title">{{ product.name }}</h2>
          <a href = "#" class = "product-link">frey store produtos</a>

          <div class = "product-price">
            <p class = "new-price">Por Apenas: <span>R$ {{ product.price }}</span></p>
          </div>

          <div class = "product-detail">
            <h2>Descrição: </h2>
            <p>{{ product.description }}</p>
            <ul>
              <li>Disponível: <span>Em Estoque</span></li>
              <li>Tamanhos:
                <span v-for="s in product.sizeList" :key="s">{{ s + ' ' }}</span>
              </li>
              <li>Entrega: <span>Frete grátis entre 3 a 7 dias</span></li>
            </ul>
          </div>
          <a-divider/>
          <a-radio-group v-model:value="size" name="radioGroup">
            <a-radio v-for="s in product.sizeList" :key="s" :value="s">{{ s }}</a-radio>
          </a-radio-group>
          <a-divider/>
          <a-button style="width: 100%" class="btn-cart" type="primary" @click="checkAmountProduct({...product})">Adicionar ao carrinho</a-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {message, notification} from "ant-design-vue";

import './ProductDetailsView.css';
import {mapState} from "vuex";


export default {
  name: 'ProductView',
  computed: mapState({
    cart: (state) => state.cart,
  }),
  data(){
    return{
      product: { show: false },
      size: ''
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.getProduct()
    const imgs = document.querySelectorAll('.img-select a');
    const imgBtns = [...imgs];
    let imgId = 1;

    imgBtns.forEach((imgItem) => {
      imgItem.addEventListener('click', (event) => {
        event.preventDefault();
        imgId = imgItem.dataset.id;
        slideImage();
      });
    });

    function slideImage(){
      const displayWidth = document.querySelector('.img-showcase img:first-child').clientWidth;

      document.querySelector('.img-showcase').style.transform = `translateX(${- (imgId - 1) * displayWidth}px)`;
    }

    window.addEventListener('resize', slideImage);
  },
  methods:{
    async getProduct() {
      const productId = this.$route.params.id
      const req = await fetch('https://paware-api-ecommerce.azurewebsites.net/api/products/' + productId)
      const data = await req.json();
      this.product = {...data, show: true}
      this.product.amount = 1
    },
    addToCart(product){
      this.$store.commit('ADD_TO_CART', {...product, size: this.size});
      notification.destroy()
      const openNotification = () => {
        notification.open({
          message: 'Produto adicionado ao carrinho.',
          description: product.description,
        });
      };
      openNotification()
    },
    checkAmountProduct(product){
      if(this.size === '') return message.warning('Escolha um tamnho.')

      let cart = JSON.parse(JSON.stringify(this.cart))
      const isFound = cart.some(p => {
        return p.id === product.id;
      });
      if(isFound){
        if(cart.find(p => p.id === product.id).amount <= 14){
          this.addToCart(product);
        }
      }else{
        this.addToCart(product);
      }
    }
  }
}
</script>