<template>
  <div class="justify-content-between align-items-center p-2 items rounded">
    <div class="d-flex flex-row"><img class="rounded img-cart-product" :src="product.image" :alt="product.name">
      <div class="ml-2">
        <span class="font-weight-bold name-txt d-block">
        {{product.name}}
      </span>
        <div class = "cart-product">
          <ul>
            <li>Tamanhos: <a v-for="s in product.sizes" :key="s" :value="s">{{
                '1x' + s + ', '
              }}</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center p-2 bottom-items rounded">
      <div class="d-flex flex-row align-items-center">
        <!--
       <span class="d-block">
           <a-button v-on:click="reduceQuantity">
             <template #icon><MinusOutlined /></template>
          </a-button>
         <a-button>
             {{amount}}
          </a-button>
          <a-button v-on:click="addQuantity">
             <template #icon><PlusOutlined /></template>
            </a-button>
         </span>
         -->
        <span class="d-block font-weight-bold price-txt">
       Preço Total: R$ {{
            amount === undefined ? product.price :
                Number((product.price * amount).toFixed(2))
          }}
     </span><i class="fa fa-trash-o ml-3 text-black-50"></i></div>
      <div class="d-flex flex-row align-items-center">
        <a-button type="danger" size="small" @click="removeFromCart">
          <template #icon><DeleteOutlined /></template>
        </a-button>
      </div>
    </div>
  </div>
  <div style="padding-bottom: 10px"></div>
</template>


<script>
import { DeleteOutlined} from '@ant-design/icons-vue';
import './CartProducts.css';

export default{
  name: 'CartProductCard',
  components:{
    DeleteOutlined,
  },
  data(){
    return{
      amount: 0,
      windowWidth: window.innerWidth,
    }
  },

  props:[
    'product'
  ],
  created(){
    this.amount = this.product.amount
  },
  methods:{
    changeProductAmount(){
      this.$store.commit('CHANGE_PRODUCT_AMOUNT', {
        productId: this.product.id,
        amount: this.amount,
        size: this.size
      });
    },
    reduceQuantity(){
      if(this.amount === 1){
        this.$store.commit('REMOVE_FROM_CART', this.product)
      }

      if(this.amount >= 2){
        this.amount =  this.amount - 1;
        this.changeProductAmount()
      }
    },
    addQuantity(){
      if(this.amount <= 14){
        this.amount =  this.amount + 1;
        this.changeProductAmount()
      }
    },
    removeFromCart(){
      this.$store.commit('REMOVE_FROM_CART', this.product)
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },

}
</script>