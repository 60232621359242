<template>
  <a-form
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      name="address"
      layout="vertical"
  >
    <a-row :gutter="16">
      <a-col :xs="24" :md="8">
        <a-form-item name="cep" label="Cep">
          <a-input v-model:value="address.cep"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="estado" label="Estado"
        >
          <a-input v-model:value="address.estado"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="cidade" label="Cidade">
          <a-input v-model:value="address.cidade"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="bairro" label="Bairro">
          <a-input v-model:value="address.bairro"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="logradouro" label="Logradouro">
          <a-input v-model:value="address.logradouro"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="numero" label="Número">
          <a-input v-model:value="address.numero"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="complemento" label="Complemento">
          <a-input v-model:value="address.complemento"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item label=" ">
          <a-button :disabled="loading" :loading="loading" @click="checkValues" style="width: 100%; color: white" type="primary" class="btn-cart">Salvar</a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>

import { mapState } from 'vuex';
import {message} from "ant-design-vue";

export default{
  name: "AddressAccount",
  computed: mapState({
    user: (state) => state.user,
  }),
  data(){
    return{
      address: {
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
        logradouro: '',
        numero: '',
        isHome: true,
        isNew: true,
        complemento: ''
      },
      loading: false,
      userId: sessionStorage.getItem('userId'),
    }
  },
  methods:{
    checkValues(){
      if(Object.keys(this.address).length === 0){
        return message.error('Preencha todos os campos.');
      }
      for(const a of Object.keys(this.address)){
        if(a !== 'complemento'){
          if(this.address[a] === undefined || this.address[a] === '' || this.address[a] === null){
            return message.error('Preencha todos os campos.');
          }
        }
      }
      this.saveAddress();
    },
    async getAddress() {
      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Addresss/user/' + this.userId, {
        method: 'GET',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
      }).then(async result => {
        const data = await result.json();
        console.log(data);
        if(data.length !== 0){
          this.address = {...data[0], isNew: false};
          sessionStorage.setItem('address', JSON.stringify({...data[0], isNew: false}));
        }
      });
    },
    saveAddress(){
      this.loading = true;
      const body = {...this.address, userId: this.userId};
      const urlPut = 'https://paware-api-ecommerce.azurewebsites.net/api/Addresss/' + this.address.id;
      const urlPost = 'https://paware-api-ecommerce.azurewebsites.net/api/Addresss';
      fetch(this.address.isNew === true ? urlPost : urlPut, {
        method: this.address.isNew === true ? 'POST' : 'PUT',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
        body: JSON.stringify(body)
      }).then(async () => {
        await this.getAddress();
        this.loading = false;
      }).catch(error => console.log("Error detected: " + error));
    }
  },
  mounted(){
    if(sessionStorage.getItem('address')){
      this.address = JSON.parse(sessionStorage.getItem('address'));
    }else{
      this.getAddress();
    }
  },
}
</script>