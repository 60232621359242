<template>
  <a-form
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      name="address"
      layout="vertical"
  >
    <a-row :gutter="16">
      <a-col :xs="24" :md="8">
        <a-form-item name="cep" label="Cep">
          <a-input v-model:value="address.cep"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="estado" label="Estado"
        >
          <a-input v-model:value="address.estado"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="cidade" label="Cidade">
          <a-input v-model:value="address.cidade"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="bairro" label="Bairro">
          <a-input v-model:value="address.bairro"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="logradouro" label="Logradouro">
          <a-input v-model:value="address.logradouro"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="numero" label="Número">
          <a-input v-model:value="address.numero"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="complemento" label="Complemento">
          <a-input v-model:value="address.complemento"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item label=" ">
          <a-button @click="checkValues" style="width: 100%; color: white" type="primary" class="btn-cart">Ir para Pagamento</a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>

import { mapState } from 'vuex';
import {message, notification} from "ant-design-vue";

export default{
  name: "AddressTab",
  computed: mapState({
    user: (state) => state.user,
  }),
  props: {
    nextStep: { type: Function },
    setAddress:{ type: Function },
  },
  data(){
    return{
      address: {
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        addressOk: false
      },
    }
  },
  methods:{
    checkValues(){
      if(Object.keys(this.address).length === 0){
        return message.error('Preencha todos os campos antes de continuar.');
      }

      for(const a of Object.keys(this.address)){
        if(a !== 'complemento'){
          if(this.address[a] === undefined || this.address[a] === '' || this.address[a] === null){
            return message.error('Preencha todos os campos.');
          }
        }
      }
      if(!this.addressOk){
        this.saveAddress();
      }else{
        this.setAddress(this.address);
        this.nextStep('2');
      }
    },
    saveAddress(){
      this.loading = true;
      const body = {...this.address, userId: sessionStorage.getItem('userId')};
      const urlPost = 'https://paware-api-ecommerce.azurewebsites.net/api/Addresss';
      fetch(urlPost, {
        method: 'POST',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
        body: JSON.stringify(body)
      }).then(response => console.log(response.text()))
          .then(() => {
            this.loading = false;
            this.setAddress(this.address);
            this.nextStep('2');
          }).catch(error => console.log("Error detected: " + error));
    },
    async getAddress() {

      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Addresss/user/' + sessionStorage.getItem('userId'), {
        method: 'GET',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
      }).then(async result => {
        const data = await result.json();
        if(data.length !== 0){
          this.address = {...data[0], isNew: false};
          this.addressOk = true;
        }else{
          const openNotification = () => {
            notification.open({
              message: 'Endereço ainda não cadastrado.',
              description: 'Preencha seu endereço completo antes de continuar sua compra.',
            });
          };
          this.addressOk = false;
          openNotification();
        }
      });
    },
  },
  mounted() {
    this.getAddress();
  }
}
</script>