

<template>
  <a-tag style="font-size: 10px; height: 20px; margin: 5px" v-if="status === 'orderApproved'" color="green">
    Compra Aprovada
  </a-tag>
  <a-tag style="font-size: 10px; height: 20px; margin: 5px" v-if="status === 'packing'" color="cyan">
    Preparando
  </a-tag>
  <a-tag style="font-size: 10px; height: 20px; margin: 5px" v-if="status === 'sent'" color="blue">
    Enviado
  </a-tag>
  <a-tag style="font-size: 10px; height: 20px; margin: 5px" v-if="status === 'delivered'" color="green">
    Entregue
  </a-tag>
</template>


<script>

export default{
  name: 'OrderStatus',
  props:[
    'status'
  ],
}
</script>