<template>
  <a-row justify="space-between">
    <div class="card-form">
      <div>
        <div class="card-list">
          <div v-if="typePayment !== 'pix'">
            <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
              <div class="card-item__side -front">
                <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }" v-bind:style="focusElementStyle" ref="focusElement"></div>
                <div class="card-item__cover">
                  <img
                      v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'" class="card-item__bg">
                </div>

                <div class="card-item__wrapper">
                  <div class="card-item__top">
                    <img src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png" class="card-item__chip">
                    <div class="card-item__type">
                      <transition name="slide-fade-up">
                        <img v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'" v-if="getCardType" v-bind:key="getCardType" alt="" class="card-item__typeImg">
                      </transition>
                    </div>
                  </div>
                  <label for="cardNumber" class="card-item__number" ref="cardNumber">
                    <template v-if="getCardType === 'amex'">
                 <span v-for="(n, $index) in amexCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                        class="card-item__numberItem"
                        v-if="$index > 4 && $index < 14 && cardNumber.length > $index && n.trim() !== ''"
                    >*</div>
                    <div class="card-item__numberItem"
                         :class="{ '-active' : n.trim() === '' }"
                         :key="$index" v-else-if="cardNumber.length > $index">
                      {{cardNumber[$index]}}
                    </div>
                    <div
                        class="card-item__numberItem"
                        :class="{ '-active' : n.trim() === '' }"
                        v-else
                        :key="$index + 1"
                    >{{n}}</div>
                  </transition>
                </span>
                    </template>

                    <template v-else>
                  <span v-for="(n, $index) in otherCardMask" :key="$index">
                    <transition name="slide-fade-up">
                      <div
                          class="card-item__numberItem"
                          v-if="$index > 4 && $index < 15 && cardNumber.length > $index && n.trim() !== ''"
                      >*</div>
                      <div class="card-item__numberItem"
                           :class="{ '-active' : n.trim() === '' }"
                           :key="$index" v-else-if="cardNumber.length > $index">
                        {{cardNumber[$index]}}
                      </div>
                      <div
                          class="card-item__numberItem"
                          :class="{ '-active' : n.trim() === '' }"
                          v-else
                          :key="$index + 1"
                      >{{n}}</div>
                    </transition>
                  </span>
                    </template>
                  </label>
                  <div class="card-item__content">
                    <label for="cardName" class="card-item__info" ref="cardName">
                      <div class="card-item__holder">Nome no Cartão</div>
                      <transition name="slide-fade-up">
                        <div class="card-item__name" v-if="cardName.length" key="1">
                          <transition-group name="slide-fade-right">
                            <div v-if="$index === $index">
                              <span class="card-item__nameItem" v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')" v-bind:key="$index + 1">{{n}}</span>
                            </div>
                          </transition-group>
                        </div>
                        <div class="card-item__name" v-else key="2">##########</div>
                      </transition>
                    </label>
                    <div class="card-item__date" ref="cardDate">
                      <label for="cardMonth" class="card-item__dateTitle">Expiração</label>
                      <label for="cardMonth" class="card-item__dateItem">
                        <transition name="slide-fade-up">
                          <span v-if="cardMonth" v-bind:key="cardMonth">{{cardMonth}}</span>
                          <span v-else key="2">MM</span>
                        </transition>
                      </label>
                      /
                      <label for="cardYear" class="card-item__dateItem">
                        <transition name="slide-fade-up">
                          <span v-if="cardYear" v-bind:key="cardYear">{{String(cardYear).slice(2,4)}}</span>
                          <span v-else key="2">YY</span>
                        </transition>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item__side -back">
                <div class="card-item__cover">
                  <img
                      v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'" class="card-item__bg">
                </div>
                <div class="card-item__band"></div>
                <div class="card-item__cvv">
                  <div class="card-item__cvvTitle">CVV</div>
                  <div class="card-item__cvvBand">
                  <span v-for="(n, $index) in cardCvv" :key="$index">
                    *
                  </span>

                  </div>
                  <div class="card-item__type">
                    <img v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'" v-if="getCardType" class="card-item__typeImg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="card-item">
              <img
                  src="@/assets/pix.png" class="card-item__bgpix">
            </div>
          </div>
        </div>
        <div class="card-form__inner">
          <div style="padding-bottom: 20px">
            <a-radio-group v-model:value="typePayment" name="radioGroup">
              <a-radio key="creditCard" value="Cartão de Crédito">Cartão de Crédito</a-radio>
              <a-radio key="pix" value="Pix">Pix</a-radio>
              <a-radio key="debitCard" value="Cartão de Débito">Cartão de Débito</a-radio>
            </a-radio-group>
          </div>
          <div v-if="typePayment !== 'pix'">
            <div class="card-input">
              <label for="cardNumber" class="card-input__label">Número do Cartão</label>
              <input type="text" id="cardNumber" class="card-input__input" v-mask="generateCardNumberMask" v-model="cardNumber" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardNumber" autocomplete="off">
            </div>
            <div class="card-input">
              <label for="cardName" class="card-input__label">Nome no Cartão</label>
              <input type="text" id="cardName" class="card-input__input" v-model="cardName" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardName" autocomplete="off">
            </div>
            <div class="card-form__row">
              <div class="card-form__col">
                <div class="card-form__group">
                  <label for="cardMonth" class="card-input__label">Data de Expiração</label>
                  <select class="card-input__input -select" id="cardMonth" v-model="cardMonth" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                    <option value="" disabled selected>Mês</option>
                    <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">
                      {{n < 10 ? '0' + n : n}}
                    </option>
                  </select>
                  <select class="card-input__input -select" id="cardYear" v-model="cardYear" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                    <option value="" disabled selected>Ano</option>
                    <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                      {{$index + minCardYear}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="card-form__col -cvv">
                <div class="card-input">
                  <label for="cardCvv" class="card-input__label">CVV</label>
                  <input type="text" class="card-input__input" id="cardCvv" v-mask="'####'" maxlength="4" v-model="cardCvv" v-on:focus="flipCard(true)" v-on:blur="flipCard(false)" autocomplete="off">
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <a-form
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                name="address"
                layout="vertical"
            >
              <a-form-item name="cpfPix" label="CPF Identificador">
                <a-input v-model:value="cpfPix"/>
              </a-form-item>
            </a-form>
          </div>
          <a-button @click="startCheckout" style="width: 100%" type="primary" class="btn-cart">Comprar</a-button>
        </div>
      </div>
    </div>
  </a-row>
  <div style="padding-bottom: 50px"></div>
</template>

<script>

import { mapState } from 'vuex';
import { message } from 'ant-design-vue';
import '../CheckoutView.scss';

export default{
  name: "PaymentTab",
  props: {
    setPayment:{ type: Function },
  },
  computed: mapState({
    user: (state) => state.user,
    cart: (state) => state.cart,
    getCardType () {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      return "visa"; // default type
    },
    generateCardNumberMask () {
      return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },
    minCardMonth () {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    }
  }),
  data(){
    return{
      typePayment: 'Cartão de Crédito',
      currentCardBackground: Math.floor(Math.random()* 25 + 1), // just for fun :D
      cardName: '',
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      minCardYear: new Date().getFullYear(),
      amexCardMask: '#### ###### #####',
      otherCardMask: '#### #### #### ####',
      cardNumberTemp: '',
      isCardFlipped: false,
      cpfPix: '',
      focusElementStyle: null,
      isInputFocused: false,
      windowWidth: window.innerWidth
    }
  },
  methods:{
    startCheckout(){
      let products = [];
      let resume = 0;

      for(const product of this.cart){
        products.push({
          referenceProductId: product.id,
          amount: product.amount,
          specifications: JSON.stringify(product.sizes)
        });
        resume += product.price * (typeof product.amount === 'undefined' ? 1 : product.amount);
      }

      const body = {
        userId: sessionStorage.getItem('userId'),
        payamentMethod: this.typePayment,
        products: products,
        status: 'orderApproved',
        totalCost: resume
      };

      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Orders', {
        method: 'POST',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken],
          ['cardNumber', this.cardNumber],
          ['cardHolder', this.cardName],
          ['cardExpiration', this.cardMonth + '/' + this.cardYear],
          ['cardSecurityCode', this.cardCvv]
        ],
        body: JSON.stringify(body)
      }).then(response => {
        if(response.status === 201){
          message.success('Compra realizada com sucesso.', 1.5).then(() => {
            this.$store.commit('SET_CART', []);
            this.$router.push('/account');
          });
        }
      });
    },
    flipCard (status) {
      this.isCardFlipped = status;
    },
    focusInput (e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      }
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
  },
  watch: {
    cardYear () {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    }
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    document.getElementById("cardNumber").focus();
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },
}
</script>