<script setup>
import { RouterLink } from 'vue-router'
import "jquery/dist/jquery.slim.min.js";
import "popper.js/dist/umd/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

</script>
<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white w-100 navigation" id="navbar">
    <div class="container">
      <RouterLink class="navbar-brand font-weight-bold" to="/">Frey Store</RouterLink>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
              aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse " id="main-navbar">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <RouterLink class="nav-link" to="/">Home</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link" to="/cart">Carrinho</RouterLink>
          </li>
          <li class="nav-item dropdown dropdown-slide">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button" data-delay="350"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Categorias
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown5">
              <li v-for="category in categories" :key="category.id">
                <RouterLink class="nav-link" :to="{path: '/category/' + category.id}">{{ category.name }}</RouterLink>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link" to="/account">Minha Conta</RouterLink>
          </li>
        </ul>
      </div>

      <ul class="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">
        <li class="dropdown cart-nav dropdown-slide list-inline-item">
          <a-badge :count="cart.length">
            <a-button @click="this.$router.push('/cart')" style="background: black" type="link" size="large" shape="circle">
              <template #icon><ShoppingCartOutlined style="color: white" /></template>
            </a-button>
          </a-badge>
          <div class="dropdown-menu cart-dropdown">
            <div v-for="product in cart" :key="product.id" class="media">
              <RouterLink  class="nav-link" :to="{path: '/product/' + product.id}">
                <img class="media-object mr-2 rounded" :src="product.image" :alt="product.name" />
              </RouterLink>
              <div class="media-body product-info">
                <h6 class="cart-name">{{ product.name }}</h6>
                <div class="cart-price">
                  <span>{{ product.amount }} x {{ product.price }}</span>
                </div>
              </div>
            </div>

            <div class="cart-summary">
              <span class="title-total h6">Total</span>
              <span class="total-price h6">R$ {{ Number((getResume()).toFixed(2)) }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { ShoppingCartOutlined  } from '@ant-design/icons-vue';
import { mapState } from 'vuex';
import './HeaderComponent.css';

export default{
  name: "HeaderComponent",
  data(){
    return{
      categories: null
    }
  },
  computed: mapState({
    user: (state) => state.user,
    cart: (state) => state.cart,
  }),
  components: { ShoppingCartOutlined },
  methods:{
    getResume(){
      let resume = 0;
      this.cart.map(product => {
        resume += product.price * (typeof product.amount === 'undefined' ? 1 : product.amount);
      });
      return resume;
    },
    async getCategories() {
      const req = await fetch('https://paware-api-ecommerce.azurewebsites.net/api/categories')
      const data = await req.json();
      this.categories = data
    }
  },
  mounted(){
    this.getCategories()
  }
}
</script>