<template>
  <div className="login-container">
    <div class="account section">
      <div class="container">
        <div @click="login" class="google-btn">
          <div class="google-icon-wrapper">
            <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
          </div>
          <p class="btn-text"><b>Entrar pelo Google</b></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAuth, signInWithPopup, GoogleAuthProvider,} from "firebase/auth";
import './LoginView.scss';
export default{
  name: 'LoginView',
  methods:{
    async checkUser() {
      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Customers/' + sessionStorage.getItem('userId'), {
        method: 'GET',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
      }).then(async () => {
      }).catch(() => {
        const body = {
          phone: 'Não Cadastrado',
          name: this.user.name,
          cpf: 'Não Cadastrado',
          email: this.user.email,
          userId: sessionStorage.getItem('userId'), id: sessionStorage.getItem('userId')
        };

        fetch('https://paware-api-ecommerce.azurewebsites.net/api/Customers', {
          method: 'POST',
          headers: [
            ["Content-Type", "application/json"],
            ["Content-Type", "text/plain"],
            ['google_authorization', this.user.accessToken]
          ],
          body: JSON.stringify(body)
        }).then(response => response.text())
            .then(() => {
              this.loading = false;
              sessionStorage.setItem('userDetails', JSON.stringify(body));
            }).catch(error => console.log("Error detected: " + error));
      });
    },
    login(){
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      signInWithPopup(auth, provider)
          .then((result) => {
            const credential = GoogleAuthProvider.credential(result._tokenResponse.idToken, result.user.accessToken);
            const user = result.user;
            this.$store.commit('SET_USER', {
              name: user.displayName,
              email: user.email,
              refreshToken: user.refreshToken,
              accessToken: user.accessToken,
              credential: credential,
            });
            sessionStorage.setItem('userId', user.uid);
            sessionStorage.setItem('address', undefined);
            this.checkUser();
          });
      //   .catch((error) => {
      //     // Handle Errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // The email of the user's account used.
      //     const email = error.customData.email;
      //     // The AuthCredential type that was used.
      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     // ...
      // });
    },
    logout(){
      this.$store.commit('UNSET_USER')
    }
  },
  computed: mapState({
    user: (state) => state.user
  }),
}
</script>