<template>
  <section class="section products-main">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="title text-center">
            <h2 v-if="category !== null">{{category.name}}</h2>
            <p v-if="category !== null">{{category.name}} todos os produtos</p>
          </div>
        </div>
      </div>

      <div v-if="windowWidth > 650" class="row">
        <div v-for="product in products" :key="product.id" class="col-lg-3 col-12 col-md-6 col-sm-6 mb-5" >
          <ProductCard :product="product"/>
        </div>
      </div>
      <div v-else class="row">
        <div v-for="product in products" :key="product.id" class="col-lg-8 col-6" >
          <ProductCard :product="product"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import ProductCard from "@/components/ProductCard/ProductCard";

export default {
  name: 'CategoryView',
  components: {
    ProductCard
  },
  data(){
    return{
      products: [],
      category: null,
      windowWidth: window.innerWidth
    }
  },
  methods:{
    async getProduct() {
      const categoryId = this.$route.params.id
      const req = await fetch('https://paware-api-ecommerce.azurewebsites.net/api/products/category/' + categoryId)
      const data = await req.json();
      this.products = data
      console.log(data)
    },
    async getCategory() {
      const categoryId = this.$route.params.id
      const req = await fetch('https://paware-api-ecommerce.azurewebsites.net/api/categories/' + categoryId)
      const data = await req.json();
      this.category = data
    },
  },
  mounted(){
    this.getProduct();
    this.getCategory();
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },
  watch: {
    $route() {
      this.getProduct();
      this.getCategory();
    }
  },
}
</script>