<template>
  <TopHeader/>
  <div style="padding-top: 50px"></div>
  <div class="container">
    <div v-if="!loading">
      <div v-if="windowWidth > 650" class="row">
        <div v-for="product in products" :key="product.id" class="col-lg-3 col-12 col-md-6 col-sm-6 mb-5" >
          <ProductCard :product="product"/>
        </div>
      </div>
      <div v-else class="row">
        <div v-for="product in products" :key="product.id" class="col-lg-8 col-6" >
          <ProductCard :product="product"/>
        </div>
      </div>
    </div>
    <div style="padding-top: 15px" v-else >
      <div v-if="windowWidth > 650" class="row">
        <div v-for="product in [...Array(20).keys()]" :key="product.id" class="col-lg-3 col-12 col-md-6 col-sm-6 mb-5" >
          <LoadingCard/>
        </div>
      </div>
      <div v-else class="row">
        <div v-for="product in [...Array(20).keys()]" :key="product.id" class="col-lg-6 col-6" >
          <LoadingCard/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductCard from "@/components/ProductCard/ProductCard";
import './HomeView.css';
import LoadingCard from "@/components/Loadings/LoadingCard";
import TopHeader from "@/components/TopHeader/TopHeader";

export default {
  name: 'HomeView',
  data(){
    return{
      products: [],
      categories: [],
      loading: true,
      windowWidth: window.innerWidth
    }
  },
  components: {
    LoadingCard,
    ProductCard,
    TopHeader
  },
  methods:{
    async getProducts() {
      const req = await fetch('https://paware-api-ecommerce.azurewebsites.net/api/products');
      this.products = await req.json();
      this.loading = false;
    },
    async getCategories() {
      const req = await fetch('https://paware-api-ecommerce.azurewebsites.net/api/categories');
      this.categories = await req.json();
    },
  },
  mounted(){
    this.getProducts()
    this.getCategories()
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },
}
</script>