<template>
  <a-form
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      name="address"
      layout="vertical"
  >
    <a-row :gutter="16">
      <a-col :xs="24" :md="8">
        <a-form-item name="name" label="Nome">
          <a-input v-model:value="user.name"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="email" label="Email">
          <a-input :disabled="true" v-model:value="user.email"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="phone" label="Telefone">
          <a-input v-model:value="account.phone"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item name="cpf" label="CPF"
        >
          <a-input v-model:value="account.cpf"/>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-form-item label=" ">
          <a-button :disabled="loading" :loading="loading" @click="checkValues" style="width: 100%; color: white" type="primary" class="btn-cart">Salvar</a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>

import { mapState } from 'vuex';
import {message} from "ant-design-vue";

export default{
  name: "AccountDetails",
  computed: mapState({
    user: (state) => state.user,
  }),
  data(){
    return {
      account: {
        phone: '',
        name: '',
        cpf: '',
        email: '',
      },
      userId: sessionStorage.getItem('userId'),
      loading: false
    }
  },
  methods:{
    async getUser() {

      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Customers/' + this.userId, {
        method: 'GET',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
      }).then(async result => {
        const data = await result.json();
        this.account = {
          phone: data?.phone,
          name: data.name,
          cpf: data?.cpf,
          email: data.email,
        }
        sessionStorage.setItem(('userDetails'), JSON.stringify(this.account));
      });
    },
    checkValues(){
      for(const a of Object.keys(this.account)){
        if(this.account[a] === undefined || this.account[a] === '' || this.account[a] === null){
          return message.error('Preencha todos os campos.');
        }
      }
      this.saveUser();
    },
    saveUser(){
      this.loading = true;
      const body = {...this.account, userId: this.userId, id: this.userId};

      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Customers/' + this.userId, {
        method: 'PUT',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
        body: JSON.stringify(body)
      }).then(response => response.text())
          .then(async () => {
            this.loading = false;
            await this.getUser();
          }).catch(error => console.log("Error detected: " + error));
    }
  },
  mounted(){
    if(sessionStorage.getItem('userDetails')){
      this.account = JSON.parse(sessionStorage.getItem('userDetails'));
    }else{
      this.getUser();
    }
  },
}
</script>