<template>
  <section class="page-header">
    <div class="overly"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="content text-center">
            <h3 class="mb-3">Finalizar Pagamento</h3>
            <p>Finalize sua compra para receber seus produtos.</p>

            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-transparent justify-content-center">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Comprar</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <a-tabs v-model:activeKey="checkoutTab">
        <a-tab-pane key="1" force-render>
          <template #tab><span><IdcardOutlined style="font-size: 22px;"/>Endereço</span></template>
          <AddressTab :next-step="nextStep" :set-address="setAddress"/>
        </a-tab-pane>
        <a-tab-pane key="2" force-render>
          <template #tab><span><ShoppingOutlined style="font-size: 22px;"/>Pagamento</span>
          </template>
          <PaymentTab :set-payment="setPayment"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </section>
  <a-modal :footer="null"
           :closable="false"
           :onOk="()=> this.showModalLogin = true"
           :onCancel="()=> this.showModalLogin = true"
           v-model:visible="showModalLogin" title="Entre para finalizar sua compra.">
  </a-modal>
</template>

<script>

import { mapState } from 'vuex';
import AddressTab from "@/views/Checkout/components/AddressTab";
import PaymentTab from "@/views/Checkout/components/PaymentTab";


export default{
  name: "CheckoutView",
  components: {PaymentTab, AddressTab},
  computed: mapState({
    user: (state) => state.user,
    cart: (state) => state.cart,
  }),
  data(){
    return{
      checkoutTab: '1',
      address: Map,
      payment: Map,
      windowWidth: window.innerWidth,
    }
  },
  methods:{
    nextStep(step){
      this.checkoutTab = step;
    },
    setAddress(address){
      this.address = address;
    },
    setPayment(payment){
      this.address = payment;
    },
  },
}
</script>