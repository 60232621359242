<template>
  <div class="container">
    <section class="page-header">
      <div class="overly"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="content text-center">
              <h3 class="mb-3">Minha Conta</h3>
              <p>Detalhes completo do seu cadastro na Frey Store.</p>

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent justify-content-center">
                  <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Minha Conta</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="user !== undefined">
      <div class="container-account">
        <a-layout>
          <a-layout-sider
              breakpoint="lg"
              collapsed-width="0"
              theme="light"
          >
            <a-menu
                class="menu-option"
                v-model:selectedKeys="selectedKeys"
                mode="inline"
            >
              <a-sub-menu key="accountOptions">
                <template #icon><SolutionOutlined /></template>
                <template #title>Meus Dados</template>
                <a-menu-item key="account">
                  <template #icon>
                    <UserOutlined />
                  </template>
                  Minha Conta
                </a-menu-item>
                <a-menu-item key="address">
                  <template #icon>
                    <EnvironmentOutlined />
                  </template>
                  Meu Endereço
                </a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="ordersOptions">
                <template #icon><ShoppingOutlined /></template>
                <template #title>Meus Pedidos</template>
                <a-menu-item key="orders">
                  <template #icon>
                    <TagsOutlined />
                  </template>
                  Todos
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-layout-sider>
          <a-layout>
            <a-layout-content :style="{ margin: '15px', padding: '35px' }">
              <div :style="{ padding: '15px', background: '#fff', minHeight: '350px' }">
                <AccountDetails v-if="selectedKeys[0] === 'account'" />
                <AddressAccount v-if="selectedKeys[0] === 'address'" />
                <div v-if="selectedKeys[0] === 'orders'">
                  <div v-if="orders.length !== 0">
                    <OrdersCard v-for="order in orders" :key="order.id" :value="order.id" :order="order" />
                  </div>
                  <div v-else>
                    <section class="page-header">
                      <div class="overly"></div>
                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-lg-6">
                            <div class="content text-center">
                              <h3 class="mb-3">Pedidos</h3>
                              <p>Você não possuí nenhum pedido no momento.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </div>
    </div>
    <div v-else>
      <LoginView/>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { ShoppingOutlined, TagsOutlined, EnvironmentOutlined, UserOutlined, SolutionOutlined } from '@ant-design/icons-vue';
import './Account.css';
import {ref} from 'vue';
import AddressAccount from '@/views/Account/components/AddressAccount';
import AccountDetails from '@/views/Account/components/AccountDetails';
import OrdersCard from '@/views/Account/components/OrdersCard';
import LoginView from "@/views/Auth/LoginView";

export default{
  name: "AccountView",
  components: {
    LoginView,
    AccountDetails,
    ShoppingOutlined, TagsOutlined, EnvironmentOutlined, UserOutlined, SolutionOutlined, AddressAccount,
    OrdersCard
  },
  computed: mapState({
    user: (state) => state.user,
  }),
  data(){
    return{
      user_details: Map,
      selectedKeys: ref(['account']),
      collapsed: ref(false),
      orders: []
    }
  },
  methods:{
    async getUserGoogleAuth(){
      const _userAuth = JSON.parse(sessionStorage.getItem("user"));

      if(_userAuth !== undefined){
        this.$store.commit('SET_USER', {
          name: _userAuth.name,
          email: _userAuth.email,
          refreshToken: _userAuth.refreshToken,
          id: _userAuth.uid,
          accessToken: _userAuth.accessToken,
          credential: _userAuth.credential
        });
      }
    },
    async getOrders() {

      fetch('https://paware-api-ecommerce.azurewebsites.net/api/Orders/user/' +
          sessionStorage.getItem('userId'), {
        method: 'GET',
        headers: [
          ["Content-Type", "application/json"],
          ["Content-Type", "text/plain"],
          ['google_authorization', this.user.accessToken]
        ],
      }).then(async result => {
        this.orders = await result.json();
      });
    }
  },
  mounted() {
    this.getUserGoogleAuth();
    this.getOrders();
  }
}
</script>