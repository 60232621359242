<template>
  <a-card :loading="true" hoverable style="width: 100%; height: 340px">
    <template #cover>
      <img src="@/assets/noimage.png" height="160" style="object-fit: fill" />
    </template>
    <a-card-meta title="Título">
      <template #description>Descrição</template>
    </a-card-meta>
  </a-card>
  <div style="padding: 10px"></div>
</template>
<script>
export default{
  name: 'LoadingCard'
}
</script>