

<template>
  <div class="justify-content-between align-items-center p-2 items rounded">
    <div class="d-flex flex-row">
      <div class="ml-2">
        <span class="font-weight-bold name-txt d-block">
        Pedido: {{order.id}}
      </span>
        <div class = "cart-product">
          <ul>
            <li>Status: <OrderStatus :status="order.status" /></li>
            <li>Método de Pagamento: {{order.payamentMethod}} </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center p-2 bottom-items rounded">
      <div class="d-flex flex-row align-items-center">
        <span class="d-block font-weight-bold price-txt">
          Total: R$ {{order.totalCost}}
     </span><i class="fa fa-trash-o ml-3 text-black-50"></i></div>
      <div class="d-flex flex-row align-items-center">
        <a-button style="width: 100%" type="primary" class="btn-cart">Abrir</a-button>
      </div>
    </div>
  </div>
  <div style="padding-bottom: 10px"></div>
</template>


<script>

import '../../../components/CartProducts/CartProducts.css';
import OrderStatus from "@/views/Account/components/services/OrderStatus";
import {mapState} from "vuex";

export default{
  name: 'OrdersCard',
  components: {OrderStatus},
  computed: mapState({
    user: (state) => state.user,
  }),
  data(){
    return{
      windowWidth: window.innerWidth,
      loading: false,
      userId: sessionStorage.getItem('userId')
    }
  },
  props:[
    'order'
  ],
  methods:{},
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },

}
</script>